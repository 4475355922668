import PoolPreviewCard, {HomePoolPreviewCard} from "./PoolPreviewCard";
import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { Row, Col, Card, Container } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import Loading from "./Loading";
import { useErrorBoundary } from "react-error-boundary";
import { StyledCard, StyledH5, StyledH4, StyledParagraph, StyledButton } from "./StyledBootstrap";
import { myAxiosInstance } from "./axiosConfig";
import { Link } from "react-router-dom";
import UserPreviewCard from "./UserPreviewCard";
import { useMediaQuery } from 'react-responsive';
import { useTheme, styled } from 'styled-components';
import { useNavigate } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RequestPreviewCard from "./RequestPreviewCard";

import { AiOutlineCloudUpload } from "react-icons/ai"; // Cloud icon
import { FaRegFile } from "react-icons/fa"; // File icon
import { IoMdClose } from "react-icons/io"; // Close icon for removing file


export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vh; /* Adjust height to fit within the homepage */
  border: 2px dashed rgba(0, 0, 0, 0.3); /* Dashed border */
  border-radius: 1rem; /* Rounded corners */
  background-color: ${({ theme }) => theme.background}; /* Light background */
  color: rgba(0, 0, 0, 0.7); /* Subtle text color */
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;

  &:hover {
    border-color: ${({ theme }) => theme.primary}; /* Highlight border on hover */
    background-color: #e9ecef; /* Slightly brighter background */
  }
`;

export const UploadText = styled.p`
  margin: 0;
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.1rem" : "0.8rem")};
  color: rgba(0, 0, 0, 0.7); /* Subtle text color */
  font-weight: bold;
`;

export const FilePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "40%" : "60%")};
  background-color: ${({ theme }) => theme.primary}; /* File preview background */
  border-radius: 1rem;
  padding: 1.5rem;
  transition: background-color 0.3s ease; /* Add smooth transition for background color */

  &:hover {
    //background-color: #4a496b; /* Slightly brighter background color on hover */
  }
`;


export const FileIcon = styled(FaRegFile)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "3.5rem" : "2.5rem")};
  color: white;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const FileName = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis for overflowed text */
  max-width: 100%; /* Set a maximum width */
`;

export const RemoveFileButton = styled.button`
  position: absolute;
  top: ${({ isMdOrLarger }) => (isMdOrLarger ? "0.5rem" : "0rem")};
  right: ${({ isMdOrLarger }) => (isMdOrLarger ? "0.5rem" : "0rem")};
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.onSecondary};
  }
`;

export const FileInput = styled.input`
  display: none; /* Hidden input for file selection */
`;

export const FileUploadError = styled.div`
  color: #ff6b6b; /* Error red */
  text-align: center;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;


export const FileUpload = ({ onFileUpload, onFileRemove, errorMessage, currentFile }) => {
    const [uploadedFile, setUploadedFile] = useState(currentFile);
    const fileInputRef = useRef(null);

    const theme = useTheme();
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
        const file = e.target.files[0];
        setUploadedFile(file);
        onFileUpload(file);
        }
    };

    const handleContainerClick = () => {
        if (fileInputRef.current) {
        fileInputRef.current.click();
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        setUploadedFile(file);
        onFileUpload(file);
        }
    };

    const removeFile = () => {
        setUploadedFile(null);
        onFileRemove();
    };

    return (
        <FileUploadContainer
        onClick={handleContainerClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        theme={theme}
        >
        {!uploadedFile ? (
            <>
            <AiOutlineCloudUpload style={{ fontSize: "4rem", color: theme.primary, marginBottom: "0.5rem" }} />
            <UploadText isMdOrLarger={isMdOrLarger}>Drop your resume below to be matched into a pool by Prospinity</UploadText>
            <UploadText>Size limit: 10MB</UploadText>
            <FileInput
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
            />
            </>
        ) : (
            <FilePreviewContainer theme={theme} isMdOrLarger={isMdOrLarger}>
            <FileIcon isMdOrLarger={isMdOrLarger} />
            <FileName>{uploadedFile.name}</FileName>
            <RemoveFileButton onClick={removeFile} theme={theme}>
                <IoMdClose />
            </RemoveFileButton>
            </FilePreviewContainer>
        )}
        {errorMessage && <FileUploadError>{errorMessage}</FileUploadError>}
        </FileUploadContainer>
    );
};



const Home = () => {

    const navigate = useNavigate();
    const HorizontalSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        speed: 7000,
        autoplaySpeed: 7000,
        cssEase: "linear",
        arrows: false
    };

    const VerticalSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        slidesPerRow: 2,
        autoplay: true,
        autoplaySpeed: 7000,

        vertical: true,
        speed: 5000,
        infinite: true,
        cssEase: "linear",

    };

    const themeMode = useTheme();

    const [dataArray, setDataArray] = useState([]);
    const [suggestedPoolArray, setSuggestedPoolArray] = useState([]);
    const [suggestedUserArray, setSuggestedUserArray] = useState([]);
    const [updatesArray, setUpdatesArray] = useState([]);
    const [meetingsArray, setMeetingsArray] = useState([]);
    const [isBottomLoading, setIsBottomLoading] = useState(true); // make this true
    const [homeUserDetails, setHomeUserDetails] = useState(null);
    const { reload, setReload, emailVerified, universityEmailVerified, poolingRequested, setPoolingRequested, poolingActivated } = useAuth();
    const [bottomReload, setBottomReload] = useState(1);

    const [resentEmailVerification, setResentEmailVerification] = useState(0);
    const [resentUniversityEmailVerification, setResentUniversityEmailVerification] = useState(0);

    const [activatePoolingButtonLoading, setActivatePoolingButtonLoading] = useState(false);


    const handleActivatePoolingClick = async () => {
        try {
            setActivatePoolingButtonLoading(true);
            const response = await myAxiosInstance.put('/api/enable-pooling/', { pooling_requested: true });
            setPoolingRequested(true);
        }
        catch (error) {
            console.error('Error sending portfolio request', error.response.data);
        }
        finally {
            setActivatePoolingButtonLoading(false);
        }

    };



    // const sendVerificationEmail = async (university) => {
    //     try {
    //         console.log("send v email here");

    //         if (university && resentUniversityEmailVerification == 0) {
    //             console.log("send v email here1", university);
    //             setResentUniversityEmailVerification(1);
    //             await myAxiosInstance.post('/api/resend-verification-university-email/');
    //             setResentUniversityEmailVerification(2);

    //         }
    //         else if (resentEmailVerification == 0) {
    //             console.log("send v email here2");
    //             setResentEmailVerification(1);
    //             await myAxiosInstance.post('/api/resend-verification-email/');
    //             setResentEmailVerification(2);
    //         }

    //     }
    //     catch (error) {
    //         showBoundary(error);
    //     }

    // }






    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        const getMyPoolsData = async () => {
            try {
                // console.log(await myAxiosInstance.get('/api/home/'));
                console.log("running use effect before....");
                const response = await myAxiosInstance.get('/api/home/');
                console.log("running use effect after....");
                console.log(response);
                console.log(response.data);
                setDataArray(response.data.todo_pools || []);
                setHomeUserDetails(response.data.user_details);
                setSuggestedPoolArray(response.data.todo_pools);
                setSuggestedUserArray(response.data.daily_picks);
                setMeetingsArray(response.data.todo_meetings);
                setUpdatesArray(response.data.updates);
                console.log("UPDATES", response.data.updates);

                console.log("this is what it is being set to: ");
                console.log(response.data.daily_picks[0]);
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };
        getMyPoolsData();
    }, [reload, bottomReload]);

    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    return (
        isBottomLoading ? <Loading /> : <>

            <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
                <div className='home-joyrride mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>

                    {poolingRequested && isMdOrLarger ? <><div><StyledH5 className="mt-4"><strong>Welcome, {homeUserDetails.first_name}</strong></StyledH5></div>

                        <StyledCard>
                            <StyledCard.Body>
                                <Container fluid>

                                    <Row>
                                        <Col className="d-flex flex-column justify-content-center align-items-center">
                                            <div><StyledH4><strong>{homeUserDetails.number_of_pools}</strong></StyledH4></div>
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center align-items-center">
                                            <div><StyledH4><strong>{homeUserDetails.portfolio_count}</strong></StyledH4></div>
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center align-items-center">
                                            <div><StyledH4><strong>${homeUserDetails.total_balance}</strong></StyledH4></div>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col className="d-flex flex-column justify-content-center">

                                            <div className='text-center' >Your Pools</div>
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center">
                                            <div className='text-center' >Portfolio Size</div>  {/*AMOLI CHANGE */}
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center">
                                            <div className='text-center' >Net Balance</div>
                                        </Col>
                                    </Row>
                                </Container>
                            </StyledCard.Body>
                        </StyledCard></> : <></>}

                    {!isMdOrLarger && (
                        <>
                            <StyledH5 className="mb-3 mt-3"><strong>Daily Picks</strong></StyledH5>
                            <StyledCard className="mt-3">
                                <StyledCard.Body>

                                    <div style={{ display: 'flex', overflowX: 'scroll', paddingTop: '10px', paddingBottom: '10px' }}>
                                        {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
                                            <div className="mx-2" key={index}>
                                                <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReload} setBottomReload={setBottomReload} />
                                            </div>
                                        ))}
                                    </div>
                                </StyledCard.Body>
                            </StyledCard>
                        </>
                    )}


                    {/*!emailVerified || !universityEmailVerified ?
                        <StyledCard className="mt-3">
                            <StyledCard.Body>
                                <Container fluid>
                                    <Row>
                                        <div><StyledH5><strong>Email Verification</strong></StyledH5></div>
                                    </Row>
                                    <Row>
                                        <StyledParagraph style={{ fontSize: '1.2rem' }} className="mt-3">
                                            To join pools (and get a green checkmark), please verify your email(s).
                                        </StyledParagraph>
                                        {emailVerified ? null : (
                                            <span
                                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                className='mt-2'
                                                onClick={() => sendVerificationEmail(false)}
                                            >
                                                {resentEmailVerification === 0 ? 'Resend Email Verification' :
                                                    resentEmailVerification === 1 ? <Loading /> : 'Check Your Email'}
                                            </span>
                                        )}
                                        {universityEmailVerified ? null : (
                                            <span
                                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                className='mt-2'
                                                onClick={() => sendVerificationEmail(true)}
                                            >
                                                {resentUniversityEmailVerification === 0 ? 'Resend University Email Verification' :
                                                    resentUniversityEmailVerification === 1 ? <Loading /> : 'Check Your University Email'}
                                            </span>
                                        )}

                                    </Row>
                                </Container>
                            </StyledCard.Body>
                        </StyledCard> : <></>*/}
                    {!poolingActivated && !poolingRequested && isMdOrLarger ? <>
                        <div className="mt-3">
                            <StyledH5><strong>Activate Pooling</strong></StyledH5>
                        </div>
                        <StyledCard style={{ position: 'relative' }}>
                            <StyledCard.Body>
                                <div>
                                    We are currently only allowing a select group of students to access success pools. If you are interested, click activate pooling, and we will screen your profile and reach out if you are selected.
                                </div>

                                {poolingRequested ? <StyledButton className='mt-1' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
                                    Applied
                                </StyledButton> :
                                    <StyledButton onClick={handleActivatePoolingClick} className='mt-2'>
                                        {activatePoolingButtonLoading ? <Loading /> : <>Activate Pooling</>}
                                    </StyledButton>}

                            </StyledCard.Body>

                        </StyledCard></> : <></>}


                    <div className="mt-3">
                        <StyledH5><strong>For You</strong></StyledH5>
                    </div>
                    <StyledCard style={{ position: 'relative' }}>
                        {(meetingsArray.length == 0 &&  dataArray.length == 0) ? 
                            <>
                                <StyledCard.Body style={{ fontSize: '1.2rem' }}>
                                <FileUpload
                                    onFileUpload={(file) => console.log("File uploaded:", file)}
                                    onFileRemove={() => console.log("File removed")}
                                />
                                </StyledCard.Body> 
                            </>
                        :
                            <>
                                <StyledCard.Body className='d-flex' style={{
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    gap: '1rem',
                                    paddingRight: '1.5rem',
                                    minWidth: 0,
                                    width: '100%'  // Ensure there's space on the right for the gradient
                                }}>
                                    {meetingsArray.map((meeting) => (
                                        <div className="mx-1 my-1">
                                            <RequestPreviewCard meeting={meeting} bottomReload={bottomReload} setBottomReload={setBottomReload} />
                                        </div>
                                    ))}
                                    {dataArray.map((pool) => (
                                        <div className="mx-1 my-1">
                                            <HomePoolPreviewCard data={pool} />
                                        </div>
                                    ))}
                                </StyledCard.Body>
                                {isMdOrLarger && themeMode.theme=="light" &&
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '50px',  // Adjust width as needed
                                    height: '100%',
                                    background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
                                    pointerEvents: 'none'  // Allow the scrollable content to be interacted with
                                }}></div>}</>}
                    </StyledCard>



                    {updatesArray.length == 0 ? <></> :
                        <>
                            <div className="mt-3"><StyledH5><strong>Updates</strong></StyledH5></div>
                            {updatesArray.map((update) => (
                                <StyledCard
                                    onClick={() => { navigate("/" + update.redirect_link); }}
                                    className='my-2'
                                    style={{
                                        cursor: 'pointer', // Change cursor to pointer
                                        transition: 'transform 0.3s ease', // Smooth transition for scaling
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.01)'} // Slightly larger on hover
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'} // Back to original size on hover out
                                >
                                    <div className="d-flex mx-2 my-2 px-1 py-1 align-items-center">
                                        <img
                                            src={update.profile_pic}
                                            alt={"Preview"}
                                            onError={(e) => { e.target.src = 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'; }}
                                            style={{
                                                width: '3rem', // Adjust width for less height
                                                height: '3rem', // Adjust height for less height
                                                borderRadius: '50%',
                                                marginRight: '1.5rem'
                                            }}
                                        />
                                        <div style={{ fontSize: '1.1rem' }}>
                                            {update.message}

                                        </div>
                                    </div>


                                </StyledCard>

                            ))}</>}




                </div>



                {/*isMdOrLarger && (
                    <div className="mx-2">
                        <StyledH5 className="mt-4"><strong>Daily Picks</strong></StyledH5>

                        <StyledCard style={{ height: '80vh', overflowY: 'auto' }}>
                            <StyledCard.Body>
                                {suggestedUserArray
                                    .filter(otherUserInfo => otherUserInfo.first_name !== '')
                                    .reduce((acc, curr, index) => {
                                        if (index % 2 === 0) {
                                            // Every even index, create a new row with two elements
                                            acc.push([curr]);
                                        } else {
                                            // Add the current item to the last row
                                            acc[acc.length - 1].push(curr);
                                        }
                                        return acc;
                                    }, [])
                                    .map((pair, pairIndex) => (
                                        <div className="d-flex align-items-center justify-content-center" key={pairIndex}>
                                            {pair.map((otherUserInfo, index) => (
                                                <div className="mx-1 my-2" key={index}>
                                                    <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReload} setBottomReload={setBottomReload} />
                                                </div>
                                            ))}
                                        </div>
                                    ))}

                            </StyledCard.Body>
                        </StyledCard>
                    </div>


                )*/}



            </div >

        </>
    );
}

export default Home;